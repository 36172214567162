var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Switch, TablePagination, FormControlLabel, } from '@mui/material';
export default function TablePaginationCustom(_a) {
    var dense = _a.dense, onChangeDense = _a.onChangeDense, _b = _a.rowsPerPageOptions, rowsPerPageOptions = _b === void 0 ? [5, 25, 50, 100] : _b, sx = _a.sx, other = __rest(_a, ["dense", "onChangeDense", "rowsPerPageOptions", "sx"]);
    return (_jsxs(Box, __assign({ sx: __assign({ position: 'relative' }, sx) }, { children: [_jsx(TablePagination, __assign({ rowsPerPageOptions: rowsPerPageOptions, component: "div" }, other)), onChangeDense && (_jsx(FormControlLabel, { label: "Dense", control: _jsx(Switch, { checked: dense, onChange: onChangeDense }), sx: {
                    pl: 2,
                    py: 1.5,
                    top: 0,
                    position: {
                        sm: 'absolute',
                    },
                } }))] })));
}
